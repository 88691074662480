body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .navbar{
    background-color: #fff !important;
  }
  
  .navbar-brand{
    font-size: rem;
    text-transform: capitalize;
  }
  
  .navbar a{
    font-size: 1.3rem;
    text-transform: capitalize;
  }
  
  .menu_active{
    font-weight: bold;
    border-bottom: 1px solid #565387;
  }
  
  .navbar a:hover{
    color: #3498db !important;
  }
  
  @media (max-width: 91px){
    .navbar-brand{
      font-size: 1.5rem !important;
    }
    .menu_active{
      font-weight: bold;
      border-bottom: none;
    }
  }
  /* end navbar properties */
  
  /* Main Home PAge */
  
  #header{
    width: 100%;
    /* height: 80vh; */
    margin-top: 70px;
    padding-bottom: 100px;
  }
  
  #header h1{
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    color: black;
  }
  
  #header .brand-name{
    color: #3498db;
  }
  
  #header h2{
    color: #484848;
    font-size: 24px;
  }
  
  #header .btn-get-started{
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.3s;
    color: #3498db;
    border: 2px solid #3498db;
    text-decoration: none;
  }
  
  #header .btn-get-started:hover{
    background-color: #3498db;
    color:#fff;
  }
  
  .header-img{
    text-align:right;
  }
  
  /* Animation of Image */
  #header .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  @-webkit-keyframes up-down{
    0%{
      transform: translateY(10px);
    }
    100%{
      transform: translateY(-10px);
    }
  }
  @keyframes up-down{
    0%{
      transform: translateY(10px);
    }
    100%{
      transform: translateY(-10px);
    }
  }
  
  /* CArd */
  .card-img-top{
    width: 250px;
    height:170px;
  }

  .navbar-img{
    width: 100px;
    height:120px;
  }